import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormHelperText, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import CreateAccountFields from './CreateAccountFields'
import signUpFormValidationSchema from './SignUpFormValidation'
import TermsAgreement from './TermsAgreement'
import SignUpFormSubmit from './SignUpFormSubmit'
import ViewablePlans from './ViewablePlans'
import { useDispatch, useSelector } from 'react-redux'
import { signUp } from '../../../../redux/actions/Signup.actions'
import ChooseRound from './ChooseRound'
import useQuery from '../../../hooks/UseQuery'
import Recaptcha from './Recaptcha'
import ChooseProgramType from './choose_program_type/ChooseProgramType'
import FormHeader from './FormHeader'
import ChargifyPaymentFields from './ChargifyPaymentFields'
import SelectPlanLifestyleOnly from './lifestyle_only/SelectPlanLifestyleOnly'

const SignUpForm = ({ lifestyle }) => {
  const dispatch = useDispatch()
  const createAccountSectionRef = useRef(null)
  const selectProgramCategoryRef = useRef(null)
  const captchaRef = useRef(null)
  const chargifyForm = useRef(null)
  const { token: securityToken, fail } = useSelector(
    (state) => state.signupSecurityToken
  )

  const { currentAndNextRound } = useSelector((state) => state.round)
  const [termsAgreed, setTermsAgreed] = useState(false)
  const [selectedRoundId, setSelectedRoundID] = useState(null)
  const [returnMemberId, setReturnMemberId] = useState('')
  const [paymentFieldsError, setPaymentFieldsError] = useState(false)
  const [chargifyLoading, setChargifyLoading] = useState(false)
  const [submissionAttempt, setSubmissionAttempt] = useState(false)
  const [captchaToken, setCaptchaToken] = useState(null)
  const [captchaError, setCaptchaError] = useState(false)
  const { utm_coupon: couponCode } = useQuery(['utm_coupon'])
  const [programType, setProgramType] = useState(null)
  const chargify = useRef(new window.Chargify())

  const LOCAL_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === 'local'
  const chargifySite = `https://${process.env.REACT_APP_CHARGIFY_SUBDOMAIN}.chargify.com`

  useEffect(() => {
    // https://github.com/chargify/chargify-js-react-example
    if (securityToken) {
      chargify.current.load({
        // selector where the iframe will be included in the host's HTML (i.e. '#chargify-form')
        // optional if you have a `selector` on each and every field
        selector: '#chargify_form',
        publicKey: process.env.REACT_APP_CHARGIFYJS_PUBLIC_KEY,
        securityToken: securityToken,
        type: 'card',
        serverHost: chargifySite,
        fields: {
          firstName: {
            selector: '#cc_first_name',
            label: 'First Name',
            placeholder: 'John',
            required: true,
            message: 'Invalid First Name',
            maxlength: '30'
          },
          lastName: {
            selector: '#cc_last_name',
            label: 'Last Name',
            placeholder: 'Smith',
            required: true,
            message: 'Invalid Last Name',
            maxlength: '30'
          },
          number: {
            selector: '#cc_number',
            label: 'Card Number',
            placeholder: 'Card Number',
            message: 'Invalid Card',
            required: true
          },
          month: {
            selector: '#cc_month',
            label: '',
            placeholder: 'MM',
            message: 'Invalid Month',
            required: true
          },
          year: {
            selector: '#cc_year',
            label: '',
            placeholder: 'YYYY',
            message: 'Invalid Year',
            required: true
          },
          cvv: {
            selector: '#cc_cvv',
            label: 'CVC',
            placeholder: 'CVC',
            message: 'Invalid CVC',
            required: true
          }
        }
      })
    }

    return () => chargify.current.unload()
  }, [securityToken])

  /* billingOption, productHandle, & paymentPlan are all required fields for signup formik values but no initial
     values are provided as extra security to ensure no signup is attempted without a program being selected
  */
  const formik = useFormik({
    initialValues: {
      couponCode: couponCode?.toUpperCase(),
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordReType: '',
      paymentMethod: 'chargify'
    },
    validationSchema: signUpFormValidationSchema(),
    onSubmit: async (values) => {
      // Set round to sign up to:
      if (selectedRoundId) values.roundId = selectedRoundId

      // Set return member id if member has logged in:
      if (returnMemberId) values.memberId = returnMemberId

      setChargifyLoading(true)
      // Payment field validation:
      await chargify.current.token(
        chargifyForm.current,
        (token) => {
          values.chargifyToken = token
          setChargifyLoading(false)
          dispatch(signUp(values))
        },
        (error) => {
          setChargifyLoading(false)
          console.log('{host} token ERROR - err: ', error)
          setPaymentFieldsError(true)
          setTimeout(() => setPaymentFieldsError(false), 3000)
        }
      )
    }
  })
  const classes = useStyles()
  const submitClickHandler = () => {
    // Only run captcha check for dev + production:
    if (!LOCAL_ENVIRONMENT) {
      setCaptchaError(false)
      captchaRef.current.reset()
      formik.values.captchaToken = captchaToken
      if (!captchaToken) {
        setCaptchaError(true)
        return
      }
    }

    setSubmissionAttempt(true)
    if (!termsAgreed) return

    // If no program is selected, scroll to error:
    if (!formik.values?.paymentPlan) {
      selectProgramCategoryRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      return
    }

    // If error in creating account fields, scroll to account section & don't submit form:
    if (
      formik.errors.email ||
      formik.errors.firstName ||
      formik.errors.lastName ||
      formik.errors.password ||
      formik.errors.passwordReType
    ) {
      // window.scrollTo({ top: 600, behavior: 'smooth' })
      createAccountSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      return
    }
    formik.submitForm()
  }

  const roundChangeHandler = (roundId) => {
    setSelectedRoundID(roundId)
  }

  const returnMemberLoggedInHandler = (id) => {
    setReturnMemberId(id)
  }

  const roundDateExists = currentAndNextRound?.currentRoundDate

  return (
    <div className={classes.formContainer}>
      {!lifestyle && (
        <>
          <div className={classes.topHeader} ref={selectProgramCategoryRef}>
            <div className={classes.formProgramHelp}>
              <p>Join now and get organised with pre-season tasks and instant access to recipes and workouts.</p>
              <p>Simply choose your membership, create your account and we&apos;ll help you select the best program. </p>
              <p>
                From <b>The Menopause Method</b> to <b>Learn to Run</b> - with 16 programs, we&apos;ve got you covered!
              </p>
            </div>
            <br />
            <div style={{textAlign: 'center'}}>
              <FormHeader
                  defaultStepNumber={1}
                  title='Choose from 2 membership options:'
              />
            </div>
          </div>
          <ChooseProgramType
            selectedProgramType={programType}
            setSelectedProgramType={setProgramType}
          />
        </>
      )}
      <div className={classes.signupContainer}>
        <div className={classes.container}>
          <FormHeader
            defaultStepNumber={lifestyle ? 1 : 2}
            roundDate={roundDateExists}
            title='Select your plan'
          />
          {lifestyle ? (
            <SelectPlanLifestyleOnly
              formik={formik}
              submissionAttempt={submissionAttempt}
            />
          ) : (
            <ViewablePlans
              formik={formik}
              submissionAttempt={submissionAttempt}
              userSelectedProgram={programType}
            />
          )}

          {currentAndNextRound?.currentRoundDate && (
            <div className={classes.formSection}>
              <FormHeader
                defaultStepNumber={lifestyle ? 2 : 3}
                title='Choose your round'
              />
              <ChooseRound onRoundChange={roundChangeHandler} />
            </div>
          )}
          <div className={classes.createAcccountFieldSection}>
            <FormHeader
              defaultStepNumber={lifestyle ? 2 : 3}
              adjustableStep={true}
              roundDate={roundDateExists}
              title='Create your account'
              ref={createAccountSectionRef}
            />
            <CreateAccountFields
              formik={formik}
              setReturnMemberId={returnMemberLoggedInHandler}
            />
          </div>
          <div className={classes.formSection}>
            <FormHeader
              defaultStepNumber={lifestyle ? 3 : 4}
              roundDate={roundDateExists}
              adjustableStep={true}
              title='Payment details'
            />
            <ChargifyPaymentFields
              securityToken={securityToken}
              ref={chargifyForm}
              fail={fail}
            />
            {paymentFieldsError && submissionAttempt && (
              <FormHelperText className={classes.error}>
                Invalid Credit Card Details
              </FormHelperText>
            )}
          </div>
          <TermsAgreement
            termsAgreed={termsAgreed}
            setTermsAgreed={setTermsAgreed}
            submissionAttempt={submissionAttempt}
            termsLink={process.env.REACT_APP_PUBLIC_SITE_TERMS}
          />
          {!LOCAL_ENVIRONMENT && (
            <Recaptcha
              captchaRef={captchaRef}
              setCaptchaToken={setCaptchaToken}
              captchaError={captchaError}
            />
          )}
          <SignUpFormSubmit
            submitClickHandler={submitClickHandler}
            chargifyLoading={chargifyLoading}
            formik={formik}
            selectedRound={selectedRoundId}
          />
          <div className={classes.ssl}>
            <span>Protected by SSL technology</span>
          </div>
        </div>
      </div>
    </div>
  )
}

SignUpForm.defaultProps = {
  lifestyle: true
}

SignUpForm.propTypes = {
  lifestyle: PropTypes.bool
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    maxWidth: '600px',
    margin: 'auto'
  },
  signupContainer: {
    maxWidth: '600px',
    // backgroundColor: theme.palette.signup.grey,
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto'
  },
  container: {
    margin: '0 43px',
    marginBottom: '43px',
    marginTop: '20px',
    width: '100%',
    fontFamily: 'Poppins',
    textAlign: 'center',
    // fontSize: '17px'
    fontSize: '22px'
  },
  formSection: {
    marginTop: '48px'
  },
  createAcccountFieldSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '48px'
  },
  formHeader: {
    // fontWeight: 700,
    // fontSize: '17px'
  },
  formTitle: {
    fontSize: '25px',
    fontWeight: 700,
    margin: 'auto',
    textAlign: 'center',
    marginTop: '60px'
  },
  lock: {
    marginRight: '1rem'
  },
  ssl: {
    textAlign: 'center',
    width: '100%',
    fontSize: '14px',
    color: '#6B7280',
    fontFamily: 'Poppins'
  },
  topHeader: {
    // textAlign: 'center',
    marginTop: '50px',
    fontFamily: 'Poppins',
    fontSize: '22px'
  },
  error: {
    color: '#EF5353'
  },
  formProgramHelp: {
    fontSize: '22px',
    textAlign: 'center',
    marginRight: '15px'
  },
  [theme.breakpoints.down(1500)]: {
    container: {
      marginLeft: '25px',
      marginRight: '25px'
    }
  },
  [theme.breakpoints.down('md')]: {
    container: {
      marginTop: '1.5rem'
    }
  },
  [theme.breakpoints.down('sm')]: {
    container: {
      marginLeft: '15px',
      marginRight: '15px',
      fontSize: '18px'
    },
    topHeader: {
      marginLeft: '15px',
      fontSize: '18px'
    },
    formProgramHelp: {
      fontSize: '18px'
    }
  }
}))

export default SignUpForm
