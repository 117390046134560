import React from 'react'
import PropTypes from 'prop-types'

import TaskContentSubHeader from '../../TaskContentSubHeader'
import NextTaskLink from '../NextTaskLink'
import navigateToGoSite from '../../../../../helpers/NavigateToGoSite'

const MeasureUpWeighingYourself = ({ nextTaskClick, nextTask }) => {
  return (
    <div>
      <TaskContentSubHeader title='Weighing Yourself' />
      <p>
        Weigh yourself as soon as you wake up in the morning - preferably without clothes on and after you&apos;ve had a
        wee
      </p>
      <p>Always use the same scales.</p>
      <p>
        I recommend the Fitbit Aria scale. The beauty of using the Aria scale is that you can connect it to the 12WBT
        site for easy weekly weigh-ins and tracking.
        <br />
        <a href={navigateToGoSite('help/622-how-do-i-connect-my-fitbit-account-to-my-12wbt-account')}>Read more</a>
      </p>
      <NextTaskLink link='Measure Chest, Waist, Hips' onClick={() => nextTaskClick(nextTask)} />
    </div>
  )
}

MeasureUpWeighingYourself.propTypes = {
  nextTaskClick: PropTypes.func.isRequired,
  nextTask: PropTypes.string.isRequired
}

export default MeasureUpWeighingYourself
