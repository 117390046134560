import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core'
import DefaultClearBtn from '../../../../../../../components/buttons/DefaultClearBtn'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useSelector } from 'react-redux'
import moment from 'moment'

const WhichProgramShouldYouDo = () => {
  const classes = useStyles()
  const [memberIsFiftyOrOver, setMemberIsFiftyOrOver] = useState(false)
  const [ageCalculated, setAgeCalculated] = useState(false)
  const targetScore = useSelector(
    (state) => state.fitnessTest.fitnessTestImprovements?.score
  )
  const currentSubscriptionProgram = useSelector(
    (state) => state.currentSubscription.currentSubscription.exercise_plan_name
  )
  const { profile } = useSelector((state) => state.profile)

  useEffect(() => {
    if (profile) {
      const duration = moment.duration(moment(profile?.birthday).diff(moment()))
      const age = Math.abs(Math.floor(duration.asYears()))
      if (age > 50) {
        setMemberIsFiftyOrOver(true)
      }
      setAgeCalculated(true)
    }
  }, [profile])

  const [rows, setRows] = useState([
    { id: 1, score: '0 - 9', fitnessProgram: 'Move', minScore: 0, maxScore: 9 },
    {
      id: 2,
      score: '10 - 19',
      fitnessProgram: 'Move 2',
      minScore: 10,
      maxScore: 19
    },
    {
      id: 4,
      score: '10 - 39',
      fitnessProgram: 'Menopause Method',
      minScore: 10,
      maxScore: 39
    },
    {
      id: 3,
      score: '20 - 39',
      fitnessProgram: 'Beginner',
      minScore: 20,
      maxScore: 39
    },
    {
      id: 4,
      score: '30 - 54',
      fitnessProgram: 'Fit For Fifty',
      minScore: 30,
      maxScore: 54
    },
    {
      id: 5,
      score: '40 - 54',
      fitnessProgram: 'Intermediate',
      minScore: 40,
      maxScore: 54
    },
    {
      id: 6,
      score: '55 - 100',
      fitnessProgram: 'Advanced',
      minScore: 55,
      maxScore: 100
    }
  ])

  useEffect(() => {
    let hasBeenRecommended = false
    const newRows = []
    rows.forEach((row) => {
      if (targetScore >= row.minScore && targetScore <= row.maxScore) {
        // If over 50:
        if (memberIsFiftyOrOver) {
          if (!hasBeenRecommended) {
            if (targetScore >= 30 && targetScore <= 54) {
              if (row.fitnessProgram === 'Fit For Fifty') {
                row.isRecommended = true
                hasBeenRecommended = true
              } else {
                row.isAlsoConsider = true
              }
            } else {
              row.isRecommended = true
              hasBeenRecommended = true
            }
          }
        } else {
          // not over 50:
          if (!hasBeenRecommended && row.fitnessProgram !== 'Fit For Fifty') {
            row.isRecommended = true
            hasBeenRecommended = true
          } else if (row.fitnessProgram !== 'Fit For Fifty') {
            row.isAlsoConsider = true
          }
        }
      }

      if (row.fitnessProgram === currentSubscriptionProgram) {
        row.isCurrentProgram = true
      }
      if (row.isRecommended && row.isCurrentProgram)
        row.isOnTheRightProgram = true
      newRows.push(row)
    })
    setRows(newRows)
  }, [memberIsFiftyOrOver, ageCalculated])

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {}
    }
  }))(TableRow)

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.signup.purple,
      color: theme.palette.common.white,
      fontWeight: 'bold'
    },
    body: {
      fontSize: 14
    }
  }))(TableCell)

  return (
    <div>
      <p className={classes.title}>Which program should you do?</p>
      <p className={classes.text}>
        Your fitness score is a good indicator of how suited you are to a
        particular fitness program. Whilst you don’t have to follow my guide, I
        do recommend you choose a program in line with your results.
        <DefaultClearBtn
          title=' Update Fitness Program'
          href={`${process.env.REACT_APP_GO_SITE}my-account/exercise-meals`}
        />
      </p>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label='which program should you do table'
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Score</StyledTableCell>
              <StyledTableCell>Fitness Program</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                style={{
                  backgroundColor: row.isRecommended
                    ? '#e6e6e6'
                    : row.isOnTheRightProgram || row.isCurrentProgram
                    ? '#dcdffb'
                    : '#fffffff'
                }}
                key={row.id}
              >
                <StyledTableCell className={classes.boldTextCol}>
                  {row.score}
                </StyledTableCell>
                <StyledTableCell>{row.fitnessProgram}</StyledTableCell>
                <StyledTableCell className={classes.boldTextCol}>
                  {row.isOnTheRightProgram
                    ? "You're on the right program!"
                    : row.isCurrentProgram
                    ? 'Your current program'
                    : row.isRecommended
                    ? 'Recommended'
                    : row.isAlsoConsider
                    ? 'Also consider'
                    : ''}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: '1.5rem'
  },
  text: {
    fontSize: 16
  },
  boldTextCol: {
    fontWeight: '900'
  }
}))

export default WhichProgramShouldYouDo
