import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, makeStyles, useTheme } from '@material-ui/core'
import WhiteCard from '../../../components/WhiteCard'
import WelcomeCheckbox from '../welcome_step_one/WelcomeCheckbox'
import {
  allFemaleGoals,
  femaleGoalPrograms,
  maleGoalPrograms,
  allMaleGoals,
  GOALS
} from './WelcomeStepTwoData'
import { useDispatch, useSelector } from 'react-redux'
import { regressStepTwo } from '../../../../redux/actions/WelcomeSteps.actions'

const WelcomeStepTwoSelectFitnessGoal = ({ collapsed, formik }) => {
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.profile)
  const classes = useStyles()
  const theme = useTheme()
  const [chosenGoal, setChosenGoal] = useState(formik.values.fitnessGoal)
  const isFemale = profile?.gender === 'F' || localStorage.getItem('gender') === 'Female'

  useEffect(() => {
    formik.setFieldValue('fitnessGoal', chosenGoal, false)
    if (isFemale) {
      formik.setFieldValue(
        'exercise_plan_level',
        femaleGoalPrograms[chosenGoal].recommendedProgram,
        false
      )
      formik.setFieldValue(
        'exercise_plan_name',
        femaleGoalPrograms[chosenGoal].recommendedProgram,
        false
      )
    } else {
      formik.setFieldValue(
        'exercise_plan_level',
        maleGoalPrograms[chosenGoal].recommendedProgram,
        false
      )
      formik.setFieldValue(
        'exercise_plan_name',
        maleGoalPrograms[chosenGoal].recommendedProgram,
        false
      )
    }
  }, [chosenGoal, GOALS, femaleGoalPrograms])

  const backButtonClickHandler = () => {
    dispatch(regressStepTwo())
  }

  const renderGoals = (goalArray) => {
    return goalArray?.map((goal, i) => {
      return (
        <div key={i}>
          {i !== 0 && <Divider className={classes.divider} />}
          <WelcomeCheckbox
            color={theme.palette.signup.secondary}
            margin='.5rem 1rem .5rem 0'
            size={25}
            isChecked={chosenGoal === goal}
            label={goal}
            onClick={() => setChosenGoal(goal)}
            error={false}
          />
        </div>
      )
    })
  }

  return (
    <WhiteCard margin='1rem auto' withShadow={true}>
      <div className={classes.container}>
        <span className={classes.title}>What best describes you?</span>
        {collapsed ? (
          <div className={classes.pickedGoalRow}>
            <WelcomeCheckbox
              color={theme.palette.signup.secondary}
              margin='.5rem 1rem .5rem 0'
              size={25}
              isChecked={true}
              label={chosenGoal}
              onClick={() => {}}
              titleUppercase={true}
              error={false}
            />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.changeButton}
                onClick={backButtonClickHandler}
              >
                Change
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div>
              {isFemale
                ? renderGoals(allFemaleGoals)
                : renderGoals(allMaleGoals)}
            </div>
          </>
        )}
      </div>
    </WhiteCard>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 2rem'
  },
  title: {
    fontSize: '1.4rem',
    margin: 'auto'
  },
  divider: {
    margin: '.5rem 0',
    backgroundColor: theme.palette.greys.dark
  },
  pickedGoalRow: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    ...theme.CSS.center
  },
  changeButton: {
    color: 'white',
    backgroundColor: theme.palette.signup.purple,
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.signup.primary
    },
    width: '6rem',
    borderRadius: '12px'
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      padding: '0 .5rem'
    },
    title: {
      fontSize: '1.1rem',
      margin: '.5rem'
    },
    pickedGoalRow: {
      flexDirection: 'column',
      padding: '0'
    },
    buttonContainer: {
      marginTop: '1rem'
    },
    changeButton: {
      marginBottom: '0',
      width: '18rem',
      fontWeight: 'bold'
    }
  }
}))

WelcomeStepTwoSelectFitnessGoal.propTypes = {
  formik: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired
}

export default WelcomeStepTwoSelectFitnessGoal
