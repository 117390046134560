// Goals:
const GOALS = {
  LOSE_WEIGHT: 'I want to lose weight',
  TONE_AND_STRENGTHEN: 'I want to tone and strengthen my body',
  IMPROVE_RUNNING: 'I want to improve my running',
  PREGNANT: 'I am pregnant',
  POST_BABY_WEIGHT_LOSS: 'I want to lose the post baby weight'
}

const MALE = 'male'
const FEMALE = 'female'

// Program Titles (These map to exercise_plan_level program names in API):
const MOVE = 'Move'
const MOVE_2 = 'Move 2'
const BEGINNER = 'Beginner'
const INTERMEDIATE = 'Intermediate'
const ADVANCED = 'Advanced Lean & Fit'
const LEAN_AND_STRONG = 'Advanced Lean & Strong'
const LEARN_TO_RUN = 'Learn to Run'
const TEN_K_RUNNING = '10k Running'
const TEN_K_RUNNING_ADVANCED = '10k Running Advanced'
const HALF_MARATHON = '1/2 Marathon'
const HALF_MARATHON_ADVANCED = '1/2 Marathon Advanced'
const MARATHON = 'Marathon'
const FIT_FOR_FIFTY = 'Fit for Fifty'
const MENOPAUSE = 'Menopause Method'
const PREGNANCY = 'Pregnancy'
const POST_BABY = 'Post Baby'

// Categories:
const WEIGHT_LOSS_AND_FITNESS = 'WEIGHT LOSS & FITNESS'
const MUSCLE_SCULPTING = 'MUSCLE SCULPTING'
const RUNNING = 'RUNNING'
const PREGNANCY_OR_POST_BABY = 'PREGNANCY / POST BABY'

// Calorie Options:
const CALORIE_1200 = '1200 Calories per day'
const CALORIE_1500 = '1500 Calories per day'
const CALORIE_1700 = '1700 Calories per day'
const CALORIE_1800 = '1800 Calories per day'
const CALORIE_2000 = '2000 Calories per day'

const CAL_1200 = '1200'
const CAL_1500 = '1500'
const CAL_1700 = '1700'
const CAL_1800 = '1800'
const CAL_2000 = '2000'

const allCalorieOptions = {
  [FEMALE]: [
    CALORIE_1200,
    CALORIE_1500,
    CALORIE_1700,
    CALORIE_1800,
    CALORIE_2000
  ],
  [MALE]: [CALORIE_1800, CALORIE_2000]
}

const allFemaleGoals = [
  GOALS.LOSE_WEIGHT,
  GOALS.TONE_AND_STRENGTHEN,
  GOALS.IMPROVE_RUNNING,
  GOALS.PREGNANT,
  GOALS.POST_BABY_WEIGHT_LOSS
]
const allMaleGoals = [
  GOALS.LOSE_WEIGHT,
  GOALS.TONE_AND_STRENGTHEN,
  GOALS.IMPROVE_RUNNING
]

const calorieValuesFromPrograms = {
  1: CAL_1200,
  2: CAL_1500,
  3: CAL_1700,
  4: CAL_1800,
  5: CAL_2000,
  6: CAL_1800,
  8: CAL_2000
}

const CALORIE_OPTIONS = {
  [FEMALE]: {
    [CALORIE_1200]: {
      calories: CALORIE_1200,
      description:
        'A carefully planned calorie level for women with lower daily energy requirements, who are looking to lose weight on the Beginner, Intermediate, Learn to Run, Menopause Method or Post Baby (not breastfeeding) programs.',
      nutritionProgramId: 1,
      recipeCalorieLevel: 0
    },
    [CALORIE_1500]: {
      calories: CALORIE_1500,
      description:
        'For women looking to lose weight while following the Beginner, Intermediate, Fit for Fifty, Learn to Run, Menopause Method and Post Baby (not breastfeeding) programs. Also suitable as a minimum calorie intake for members following the Lean & Strong and 10k Running Programs.',
      nutritionProgramId: 2,
      recipeCalorieLevel: 1
    },
    [CALORIE_1700]: {
      calories: CALORIE_1700,
      description:
        'For women who are pregnant. This minimum level is set to ensure the healthy growth and development of the baby, and that mums-to-be are eating enough support their own physiological changes. All the recipes at this calorie level are specifically designed to follow the food safety standards recommended for pregnancy.',
      nutritionProgramId: 3,
      recipeCalorieLevel: 2
    },
    [CALORIE_1800]: {
      calories: CALORIE_1800,
      description:
        'For women wanting to lose 30kg or more in a sustainable way or for women maintaining their optimum weight. 1800cal is also our baseline calorie recommendation for breastfeeding mothers. Suitable for those with higher calorie requirements or those following Menopause Method, Move or Advanced 10km Running Programs.',
      nutritionProgramId: 6,
      recipeCalorieLevel: 3
    },
    [CALORIE_2000]: {
      calories: CALORIE_2000,
      description:
        'For women looking to maintain their healthy weight whilst consolidating their nutrition habits for maximum physical performance. Suitable for those women with higher calorie requirements or those on our 1/2 Marathon, 1/2 Marathon Advanced or Marathon programs.',
      nutritionProgramId: 8,
      recipeCalorieLevel: 4
    }
  },
  [MALE]: {
    [CALORIE_1800]: {
      calories: CALORIE_1800,
      description:
        'For men wanting to lose weight on the Beginner, Intermediate, Learn to Run programs or maintain weight on the Fit for Fifty program.',
      nutritionProgramId: 4,
      recipeCalorieLevel: 3
    },
    [CALORIE_2000]: {
      calories: CALORIE_2000,
      description:
        'For men wanting to maintain their weight on the Advanced, Lean & Strong, 10k Running, 10k Running Advanced, 1/2 Marathon, 1/2 Marathon Advanced or Marathon programs OR those wanting to lose weight on the Move programs.',
      nutritionProgramId: 5,
      recipeCalorieLevel: 4
    }
  }
}

const programStats = {
  [MOVE]: {
    title: MOVE,
    description:
      'For those with BMI 30+, with gentle cardio, strength and stretching sessions.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1500],
        CALORIE_OPTIONS[FEMALE][CALORIE_1800]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1800, [MALE]: CALORIE_2000 }
  },
  [MOVE_2]: {
    title: MOVE_2,
    description:
      'For those with BMI 30+ and already exercising. Combines cardio, strength and stretching to burn calories and improve muscle tone.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1500],
        CALORIE_OPTIONS[FEMALE][CALORIE_1800]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1800, [MALE]: CALORIE_2000 }
  },
  [BEGINNER]: {
    title: BEGINNER,
    description:
      'For those currently doing limited or no exercise. Designed to lose weight and get you fitter.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1200],
        CALORIE_OPTIONS[FEMALE][CALORIE_1500]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1500, [MALE]: CALORIE_1800 }
  },
  [INTERMEDIATE]: {
    title: INTERMEDIATE,
    description:
      'For those who are already exercising 1-3 times per week. Designed to lose weight and get you fitter.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1200],
        CALORIE_OPTIONS[FEMALE][CALORIE_1500]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1500, [MALE]: CALORIE_1800 }
  },
  [ADVANCED]: {
    title: 'Advanced',
    description:
      'For those who are already exercising 3+ times per week. Designed to lose weight and get you fitter.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1200],
        CALORIE_OPTIONS[FEMALE][CALORIE_1500]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1500, [MALE]: CALORIE_2000 }
  },
  [LEAN_AND_STRONG]: {
    title: 'Lean & Strong',
    description:
      'For those who exercise regularly and want to focus on muscle definition and getting stronger.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1200],
        CALORIE_OPTIONS[FEMALE][CALORIE_1500]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1500, [MALE]: CALORIE_1800 }
  },
  [LEARN_TO_RUN]: {
    title: LEARN_TO_RUN,
    description:
      'For those who exercise regularly and want to focus on muscle definition and getting stronger.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1200],
        CALORIE_OPTIONS[FEMALE][CALORIE_1500]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1500, [MALE]: CALORIE_1800 }
  },
  [TEN_K_RUNNING]: {
    title: TEN_K_RUNNING,
    description:
      'For those who can run at least 2km (or 15 min) without stopping.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1200],
        CALORIE_OPTIONS[FEMALE][CALORIE_1500]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1500, [MALE]: CALORIE_2000 }
  },
  [TEN_K_RUNNING_ADVANCED]: {
    title: TEN_K_RUNNING_ADVANCED,
    description:
      'For those who can already run 10km, but want to improve form and speed.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1500],
        CALORIE_OPTIONS[FEMALE][CALORIE_1800]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1800, [MALE]: CALORIE_2000 }
  },
  [HALF_MARATHON]: {
    title: HALF_MARATHON,
    description:
      'For those who can already run 10km (or 60 min) without stopping.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1500],
        CALORIE_OPTIONS[FEMALE][CALORIE_2000]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_2000, [MALE]: CALORIE_2000 }
  },
  [HALF_MARATHON_ADVANCED]: {
    title: HALF_MARATHON_ADVANCED,
    description:
      'For those who can already run 21km, but want to improve form and speed.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1500],
        CALORIE_OPTIONS[FEMALE][CALORIE_2000]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_2000, [MALE]: CALORIE_2000 }
  },
  [MARATHON]: {
    title: MARATHON,
    description: 'For those who can already run 21km (or 2h) without stopping.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1500],
        CALORIE_OPTIONS[FEMALE][CALORIE_2000]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_2000, [MALE]: CALORIE_2000 }
  },
  [PREGNANCY]: {
    title: PREGNANCY,
    description:
      'A safe workout plan specifically designed for women who are pregnant.',
    calorieOptions: { [FEMALE]: [CALORIE_OPTIONS[FEMALE][CALORIE_1700]] },
    recommendedCalorie: { [FEMALE]: CALORIE_1700 }
  },
  [POST_BABY]: {
    title: POST_BABY,
    description:
      'A safe and realistic workout plan for post baby mothers. 6 weeks post birth and beyond.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1200],
        CALORIE_OPTIONS[FEMALE][CALORIE_1500],
        CALORIE_OPTIONS[FEMALE][CALORIE_1800]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1800 }
  },
  [MENOPAUSE]: {
    title: MENOPAUSE,
    description:
      'For those in perimenopause, menopause and postmenopause. Designed to help manage symptoms, lose weight, enhance bone density and overall well-being.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1200],
        CALORIE_OPTIONS[FEMALE][CALORIE_1500],
        CALORIE_OPTIONS[FEMALE][CALORIE_1800]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1500 }
  },
  [FIT_FOR_FIFTY]: {
    title: FIT_FOR_FIFTY,
    description:
      'For those who are are approaching their fifties, and who are exercising 2+ days per week.',
    calorieOptions: {
      [FEMALE]: [
        CALORIE_OPTIONS[FEMALE][CALORIE_1200],
        CALORIE_OPTIONS[FEMALE][CALORIE_1500],
        CALORIE_OPTIONS[FEMALE][CALORIE_1700],
        CALORIE_OPTIONS[FEMALE][CALORIE_1800],
        CALORIE_OPTIONS[FEMALE][CALORIE_2000]
      ],
      [MALE]: [
        CALORIE_OPTIONS[MALE][CALORIE_1800],
        CALORIE_OPTIONS[MALE][CALORIE_2000]
      ]
    },
    recommendedCalorie: { [FEMALE]: CALORIE_1500, [MALE]: CALORIE_1800 }
  }
}

const femaleGoalPrograms = {
  [GOALS.LOSE_WEIGHT]: {
    categories: [
      {
        title: WEIGHT_LOSS_AND_FITNESS,
        programs: [MOVE, MOVE_2, BEGINNER, INTERMEDIATE, ADVANCED]
      },
      {
        title: RUNNING,
        programs: [LEARN_TO_RUN]
      },
      { title: PREGNANCY_OR_POST_BABY, programs: [POST_BABY] }
    ],
    recommendedProgram: BEGINNER
  },
  [GOALS.TONE_AND_STRENGTHEN]: {
    categories: [{ title: MUSCLE_SCULPTING, programs: [LEAN_AND_STRONG] }],
    recommendedProgram: LEAN_AND_STRONG
  },
  [GOALS.IMPROVE_RUNNING]: {
    categories: [
      {
        title: RUNNING,
        programs: [
          LEARN_TO_RUN,
          TEN_K_RUNNING,
          TEN_K_RUNNING_ADVANCED,
          HALF_MARATHON,
          HALF_MARATHON_ADVANCED,
          MARATHON
        ]
      }
    ],
    recommendedProgram: LEARN_TO_RUN
  },
  [GOALS.PREGNANT]: {
    categories: [{ title: PREGNANCY_OR_POST_BABY, programs: [PREGNANCY] }],
    recommendedProgram: PREGNANCY
  },
  [GOALS.POST_BABY_WEIGHT_LOSS]: {
    categories: [{ title: PREGNANCY_OR_POST_BABY, programs: [POST_BABY] }],
    recommendedProgram: POST_BABY
  }
}

const midlifeFemaleGoalPrograms = {
  [GOALS.LOSE_WEIGHT]: {
    categories: [
      {
        title: WEIGHT_LOSS_AND_FITNESS,
        programs: [
          MOVE,
          MOVE_2,
          BEGINNER,
          INTERMEDIATE,
          ADVANCED,
          FIT_FOR_FIFTY,
          MENOPAUSE
        ]
      },
      {
        title: RUNNING,
        programs: [LEARN_TO_RUN]
      },
      { title: PREGNANCY_OR_POST_BABY, programs: [POST_BABY] }
    ],
    recommendedProgram: BEGINNER
  },
  [GOALS.TONE_AND_STRENGTHEN]: {
    categories: [{ title: MUSCLE_SCULPTING, programs: [LEAN_AND_STRONG] }],
    recommendedProgram: LEAN_AND_STRONG
  },
  [GOALS.IMPROVE_RUNNING]: {
    categories: [
      {
        title: RUNNING,
        programs: [
          LEARN_TO_RUN,
          TEN_K_RUNNING,
          TEN_K_RUNNING_ADVANCED,
          HALF_MARATHON,
          HALF_MARATHON_ADVANCED,
          MARATHON
        ]
      }
    ],
    recommendedProgram: LEARN_TO_RUN
  },
  [GOALS.PREGNANT]: {
    categories: [{ title: PREGNANCY_OR_POST_BABY, programs: [PREGNANCY] }],
    recommendedProgram: PREGNANCY
  },
  [GOALS.POST_BABY_WEIGHT_LOSS]: {
    categories: [{ title: PREGNANCY_OR_POST_BABY, programs: [POST_BABY] }],
    recommendedProgram: POST_BABY
  }
}

const maleGoalPrograms = {
  [GOALS.LOSE_WEIGHT]: {
    categories: [
      {
        title: WEIGHT_LOSS_AND_FITNESS,
        programs: [
          MOVE,
          MOVE_2,
          BEGINNER,
          INTERMEDIATE,
          ADVANCED,
          FIT_FOR_FIFTY
        ]
      },
      {
        title: RUNNING,
        programs: [LEARN_TO_RUN]
      }
    ],
    recommendedProgram: BEGINNER
  },
  [GOALS.TONE_AND_STRENGTHEN]: {
    categories: [{ title: MUSCLE_SCULPTING, programs: [LEAN_AND_STRONG] }],
    recommendedProgram: LEAN_AND_STRONG
  },
  [GOALS.IMPROVE_RUNNING]: {
    categories: [
      {
        title: RUNNING,
        programs: [
          LEARN_TO_RUN,
          TEN_K_RUNNING,
          TEN_K_RUNNING_ADVANCED,
          HALF_MARATHON,
          HALF_MARATHON_ADVANCED,
          MARATHON
        ]
      }
    ],
    recommendedProgram: LEARN_TO_RUN
  }
}

const allFemalePrograms = {
  categories: [
    {
      title: WEIGHT_LOSS_AND_FITNESS,
      programs: [
        MOVE,
        MOVE_2,
        BEGINNER,
        INTERMEDIATE,
        ADVANCED,
        FIT_FOR_FIFTY,
        MENOPAUSE
      ]
    },
    { title: MUSCLE_SCULPTING, programs: [LEAN_AND_STRONG] },
    {
      title: RUNNING,
      programs: [
        LEARN_TO_RUN,
        TEN_K_RUNNING,
        TEN_K_RUNNING_ADVANCED,
        HALF_MARATHON,
        HALF_MARATHON_ADVANCED,
        MARATHON
      ]
    },
    {
      title: PREGNANCY_OR_POST_BABY,
      programs: [PREGNANCY, POST_BABY]
    }
  ]
}

const allMalePrograms = {
  categories: [
    {
      title: WEIGHT_LOSS_AND_FITNESS,
      programs: [MOVE, MOVE_2, BEGINNER, INTERMEDIATE, ADVANCED]
    },
    { title: MUSCLE_SCULPTING, programs: [LEAN_AND_STRONG] },
    {
      title: RUNNING,
      programs: [
        LEARN_TO_RUN,
        TEN_K_RUNNING,
        TEN_K_RUNNING_ADVANCED,
        HALF_MARATHON,
        HALF_MARATHON_ADVANCED,
        MARATHON
      ]
    }
  ]
}

const mealPlans = [
  {
    title: 'Time Saver Meal Plan',
    value: 'Time Saver',
    description:
      'This plan includes cooking extras for reheating, and freezable meals. Slightly less variety but saves time.'
  },
  {
    title: 'Budget Meal Plan',
    value: 'Budget Regular',
    description: 'Recipes chosen to reduce the cost of your weekly shop.'
  },
  {
    title: 'Low Carb Meal Plan',
    value: 'Low Carb',
    description:
      'Recipes designed to assist those needing to keep their carbohydrate intake low.'
  },
  {
    title: 'Menopause Meal Plan',
    value: 'Menopause',
    program: 'Menopause Method',
    description:
      'This meal plan has been designed to cater for the specific dietary needs of a woman who is in perimenopause or menopause.'
  }
]

export {
  mealPlans,
  maleGoalPrograms,
  femaleGoalPrograms,
  midlifeFemaleGoalPrograms,
  programStats,
  allCalorieOptions,
  allFemaleGoals,
  allMaleGoals,
  allMalePrograms,
  allFemalePrograms,
  CALORIE_OPTIONS,
  FIT_FOR_FIFTY,
  MENOPAUSE,
  GOALS,
  calorieValuesFromPrograms
}
