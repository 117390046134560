import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button, Divider, makeStyles, useTheme } from '@material-ui/core'
import WhiteCard from '../../../components/WhiteCard'
import WelcomeCheckbox from '../welcome_step_one/WelcomeCheckbox'
import {
  midlifeFemaleGoalPrograms,
  femaleGoalPrograms,
  programStats,
  allMalePrograms,
  allFemalePrograms,
  maleGoalPrograms
} from './WelcomeStepTwoData'
import { useDispatch, useSelector } from 'react-redux'
import { regressStepTwo } from '../../../../redux/actions/WelcomeSteps.actions'

const WelcomeStepTwoSelectProgram = ({ formik, collapsed }) => {
  const classes = useStyles()
  const [chosenProgram, setChosenProgram] = useState(
    formik.values.exercise_plan_level
  )
  const [viewingAllPrograms, setViewingAllPrograms] = useState(false)
  const [goalPrograms, setGoalPrograms] = useState(null)
  const { profile } = useSelector((state) => state.profile)
  const theme = useTheme()
  const dispatch = useDispatch()
  const goal = formik.values.fitnessGoal
  const isFemale = profile?.gender === 'F' || localStorage.getItem('gender') === 'Female'

  // Control which programs are visible:
  useEffect(() => {
    if (isFemale) {
      if (viewingAllPrograms) {
        setGoalPrograms(allFemalePrograms)
      } else {
        const birthday = profile?.birthday || moment(localStorage.getItem('dob'), 'YYYY-M-D')
        const over40s = moment().diff(birthday, 'years', false) >= 40
        over40s
          ? setGoalPrograms(midlifeFemaleGoalPrograms[goal])
          : setGoalPrograms(femaleGoalPrograms[goal])
      }
    } else {
      if (viewingAllPrograms) {
        setGoalPrograms(allMalePrograms)
      } else {
        setGoalPrograms(maleGoalPrograms[goal])
      }
    }
  }, [viewingAllPrograms, setViewingAllPrograms, goal, isFemale])

  useEffect(() => {
    formik.setFieldValue('exercise_plan_level', chosenProgram, false)
    formik.setFieldValue(
      'exercise_plan_name',
      programStats[chosenProgram]?.title
    )
  }, [chosenProgram])

  const changeProgramsClickHandler = () => {
    // If changing back to recommended programs, scroll to top of page smoothly:
    if (viewingAllPrograms) window.scrollTo({ top: 0, behavior: 'smooth' })

    // Change list of programs visible:
    setViewingAllPrograms(!viewingAllPrograms)
  }

  const backButtonClickHandler = () => {
    dispatch(regressStepTwo())
  }

  const cardTitle = () => {
    let title = 'Fitness Program'
    if (!collapsed)
      title = viewingAllPrograms
        ? 'All Fitness Programs'
        : 'Recommended Programs'
    return title
  }
  return (
    <WhiteCard margin='1rem auto' withShadow={true}>
      <div className={classes.container}>
        <div className={classes.title}>
          <span>{cardTitle()}</span>
        </div>
        {collapsed ? (
          <div className={classes.pickedGoalRow}>
            <div className={classes.pickedGoalContainer}>
              <WelcomeCheckbox
                color={theme.palette.signup.secondary}
                margin='.5rem 1rem .5rem 0'
                size={25}
                isChecked={true}
                label={programStats[chosenProgram]?.title}
                description={
                  chosenProgram ? programStats[chosenProgram].description : null
                }
                onClick={() => {}}
                error={false}
                titleUppercase={true}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.changeButton}
                onClick={backButtonClickHandler}
              >
                Change
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {goalPrograms?.categories.map((category, index) => {
              return (
                <div key={index} className={classes.innerContainer}>
                  <div className={classes.categoryHeader}>{category.title}</div>
                  <div>
                    {category.programs.map((program, index) => {
                      return (
                        <div className={classes.checkboxRow} key={`p-${index}`}>
                          {index !== 0 && (
                            <Divider className={classes.divider} />
                          )}
                          <WelcomeCheckbox
                            color={theme.palette.signup.secondary}
                            margin='.5rem 1rem .5rem 0'
                            size={25}
                            isChecked={chosenProgram === program}
                            label={programStats[program].title}
                            description={programStats[program].description}
                            onClick={() => setChosenProgram(program)}
                            error={false}
                            titleUppercase={true}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
            <div className={classes.buttonContainer}>
              <Button
                className={classes.programButton}
                onClick={changeProgramsClickHandler}
              >
                {viewingAllPrograms
                  ? 'View recommended programs'
                  : 'View all fitness programs'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </WhiteCard>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 1rem',
    overflow: 'hidden'
  },
  innerContainer: { overflow: 'hidden', padding: '1rem' },
  title: {
    fontSize: '1.4rem',
    textAlign: 'center'
  },
  categoryHeader: {
    height: '2rem',
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.transparent,
    borderTop: `1px solid #b8daff`,
    borderBottom: `1px solid #b8daff`,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1rem',
    fontSize: '.9rem'
  },
  checkboxRow: {
    margin: '1rem auto'
  },
  divider: {
    margin: '1rem 1rem',
    backgroundColor: theme.palette.greys.medium
  },
  programButton: {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: theme.palette.signup.purple,
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.signup.primary
    },
    width: '18rem',
    borderRadius: '12px'
  },
  buttonContainer: {
    ...theme.CSS.center
  },
  pickedGoalRow: {
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  changeButton: {
    color: 'white',
    backgroundColor: theme.palette.signup.purple,
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.signup.primary
    },
    width: '6rem',
    borderRadius: '12px',
    height: '2.5rem'
  },
  pickedGoalContainer: {
    paddingRight: '.5rem'
  },
  [theme.breakpoints.down('xs')]: {
    container: {
      padding: '0rem .2rem'
    },
    pickedGoalRow: {
      flexDirection: 'column',
      padding: '0'
    },
    programButton: {
      marginTop: '.5rem',
      width: '18rem',
      fontWeight: 'bold'
    },
    changeButton: {
      marginTop: '.5rem',
      width: '18rem',
      fontWeight: 'bold'
    }
  },
  [theme.breakpoints.down(350)]: {
    programButton: {
      fontSize: '.9rem'
    }
  }
}))

WelcomeStepTwoSelectProgram.propTypes = {
  formik: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired
}

export default WelcomeStepTwoSelectProgram
